import React, { useEffect, useState, useContext } from 'react';
import Loader from '../../../HOC/Loader/Loader';
import superApi from '../../../Api/Api';
import TitleBar from '../../../components/TitleBar/TitleBar';
import Setting from './Setting/Setting';
import { AuthContext } from '../../../Context/AuthContext';
import './Preferences.css';

const Preferences = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [enablePhotosValue, setEnablePhotosValue] = useState(true)
    const [enablePrivacyValue, setEnablePrivacyValue] = useState(false)
    const [enableAutoFill, setEnableAutoFill] = useState(false)
    const auth = useContext(AuthContext)
    useEffect(() => {
        superApi.get('preferences').then(response => {
            setEnablePhotosValue(response.data.enable_photo_upload)
            setEnablePrivacyValue(response.data.enable_privacy)
            setEnableAutoFill(response.data.enable_auto_fill)
            setIsLoading(false)
        }).catch(error => {
            if (error.response) {
                props.setLoadingText("Not implemented")
                // props.setLoadingText(error.response.status+" :(")
            }
            else if (error.request) {
                props.setLoadingText(error.message + " :(")

            }
        })
        
    }, [])

    const enablePhotosHandler = (val) => {
        superApi.post('preferences', {'enable_photo_upload': val}).then(response => {
            setEnablePhotosValue(val)
            auth.getProfile()
        })
    }

    const enablePrivacyHandler = (val) => {
        superApi.post('preferences', {'enable_privacy': val}).then(response => {
            setEnablePrivacyValue(val)
            auth.getProfile()
        })
    }

    const enableAutoFillHandler = (val) => {
        superApi.post('preferences', {'enable_auto_fill': val}).then(response => {
            setEnableAutoFill(val)
            auth.getProfile()
        })
    }
    return (
        <Loader text={props.loadingText} loading={isLoading}>
            <TitleBar share={props.share} items={[]} />
            <div className="Preferences">
                    {/* <Setting tooltip="Prompt to upload a photo when entering achievement" label="Enable Photo Uploads" checked={enablePhotosValue} change={enablePhotosHandler} /> */}
                    <Setting tooltip="Enable this if you don't want to share your achievements with your colleagues" label="Privacy Mode" checked={enablePrivacyValue} change={enablePrivacyHandler} />
                    <Setting tooltip="Enable this to prepopulate the acheivement dialog with the last thing you wrote" label="Enable Auto Fill" checked={enableAutoFill} change={enableAutoFillHandler} />
            </div>
        </Loader>
    );
}

export default Preferences;