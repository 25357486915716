import React, { useContext } from 'react';
import './MyTeamDetails.css';
import TeamMember from './TeamMember/TeamMember';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { AuthContext } from '../../../../Context/AuthContext';
import { Button } from 'primereact/button';
import superApi from '../../../../Api/Api';

const MyTeamDetails = (props) => {

    const auth = useContext(AuthContext);
    const showAvatar = () => {
        if (props.team.avatar) {
            return (
                <img src={superApi.defaults.baseURL +"avatar/"+ props.team.avatar} />
            )
        } else {
            return (
                <i className="material-icons">{props.captain ? "perm_identity" : "group"}</i>
            )
        }
    }

    const uploadAvatarHandler = (file) => {
        // setImageUploading(true)
        const formData = new FormData();
        formData.append("avatar", file);
        
        // reader.onload = (e) => {
            
            superApi.post('/profile/avatar/team', formData, {headers: {'Content-Type': 'multipart/form-data'}} )
            .then(
                (response) => {
                    const thumb = document.getElementsByClassName("avatar-thumb")[0];
                    const img = document.createElement("img");
                    img.src = window.URL.createObjectURL(file)
                    thumb.innerHTML = "";
                    thumb.append(img);
                    // setImageUploading(false)
            }
            )
            .catch((error) => {
                // growlRef.current.show({severity: 'error', summary: 'Fail', detail: 'Maybe unsupported file type'});
                // setImageUploading(false);
            })
        // }
        
    }

    const items = [{ label: 'Leave team', icon: 'pi pi-fw pi-ban', command: () => props.leaveTeam(props.team._id) },
    { label: 'Add Avatar', icon: 'pi pi-fw pi-user', command: () => window.alert("Not Implemented") }]

    const MEMBERS = props.team.members.map((member) => {
        let memberPoints = 0;
        // if (member.profile.achievements) {
        //     const memberProfile = auth.calculatePoints(member.profile);
        //     memberProfile.achievements.map((achievement) => {
        //         memberPoints += achievement.approved_points
        //         return null;
        //     });
        let _member = []
        if (auth.leaderboard.leaderboard !== undefined) {

            _member = auth.leaderboard.leaderboard.filter(person => person.id === member._id.$oid)
        }
        if(_member[0] !== undefined) {

            memberPoints = _member[0].points
        }
        

        return <TeamMember points={memberPoints} captain={member._id.$oid === props.team.captain.$oid} member={member} key={member._id.$oid} />

    });

    return (
        <React.Fragment>
            <TitleBar title={props.team.name} items={items} />
            <div className="MyTeamDetails">



                <ul className="team-memberlist">
                    <div className="fileUpload">

                        {/* <Button onClick={() => alert("not implemented")} className="p-button-info" label="Add Team Avatar" /> */}
                        <a onClick={() => document.getElementById("avatar-select").click()}><span className="avatar-thumb">{showAvatar()}</span></a>
                        <input name="avatar" id="avatar-select" type="file" style={{ display: 'none' }} onChange={(e) => uploadAvatarHandler(e.target.files[0])} />

                    </div>
                    {MEMBERS}
                </ul>
            </div>
        </React.Fragment>
    );
}

export default MyTeamDetails;